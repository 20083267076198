import React, { useState, useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { FirebaseContext } from "../components/Firebase";
import { getImage } from "gatsby-plugin-image";
import { Offline, Online } from "react-detect-offline";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Book from "../components/molecules/Book";
import Loader from "../components/atoms/Loader";
import Footer from "../components/organisms/Footer";
import Mountains from "../components/atoms/Mountains";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons';

const IndexPage = (props) => {

  const { user, firebase } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [usernameValue, setUsernameValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isFF, setIsFF] = useState(false);

  const [hasMontaguAccess, setHasMontaguAccess] = useState(false);

  const [codeValue, setCodeValue] = useState('');
  const [redeemErrorMessage, setRedeemErrorMessage] = useState('');
  const [redeemSuccessMessage, setRedeemSuccessMessage] = useState('');
  const [redeemLoading, setRedeemLoading] = useState(false);

  useEffect(() => {

    let firefox = window.navigator.userAgent.indexOf("Firefox");
    setIsFF(firefox > 0);

    if (user) {
      setLoading(false);

      // username
      if (user.username !== null) {
        setUsernameValue(user.username);

        // access
        if (user.montaguAccess) {
          setHasMontaguAccess(true);
        }
      }
    } else {
      // no user
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

  }, [user, hasMontaguAccess, usernameValue, loading, firebase]);

  // handle google click
  const handleGoogleClick = (e) => {
    e.preventDefault();

    setLoginLoading(true);

    // if (isFF) {
      firebase.registerGooglePopup()
      .then(() => {
        setLoginLoading(false);
        setSuccessMessage('Login successful!');
      }).catch(error => {
        setLoginLoading(false);
        setErrorMessage(error.message);
      });
    // } else {
    //   firebase.registerGoogle()
    //   .then(() => {
    //     setLoginLoading(false);
    //     setSuccessMessage('Login successful!');
    //   }).catch(error => {
    //     setLoginLoading(false);
    //     setErrorMessage(error.message);
    //   });
    // }
  }

  // handle facebook click
  const handleFacebookClick = (e) => {
    e.preventDefault();

    setLoginLoading(true);

    if (isFF) {
      firebase.registerFacebookPopup()
      .then(() => {
        setLoginLoading(false);
        setSuccessMessage('Login successful!');
      })
      .catch(error => {
        setLoginLoading(false);
        setErrorMessage(error.message);
      });
    } else {
      firebase.registerFacebook()
      .then(() => {
        setLoginLoading(false);
        setSuccessMessage('Login successful!');
      })
      .catch(error => {
        setLoginLoading(false);
        setErrorMessage(error.message);
      });
    }
  }

  // handle input change
  const handleUsernameChange = (e) => {
    e.persist();
    setErrorMessage('');
    setSuccessMessage('');
    setUsernameValue(e.target.value);
  }

  // handle username click
  const handleUsernameClick = (e) => {
    e.preventDefault();

    setLoginLoading(true);

    firebase.setUsername({
      username: usernameValue
    })
    .then(() => {
      setLoginLoading(false);
      setSuccessMessage('Now, go and climb!');
    }).catch(error => {
      setLoginLoading(false);
      setErrorMessage(error.message);
    })
  }

  // handle redeem
  const handleRedeem = (e) => {
    e.preventDefault();

    setRedeemLoading(true);

    // original || freebies || woocommerce
    if (codeValue.length === 20 || codeValue.length === 12 || codeValue.length === 15) {
      
      let guidebook;
      if (codeValue.includes('cps_')) {
        guidebook = 'Cape Peninsula';
      }
      if (codeValue.includes('cts_')) {
        guidebook = 'Cape Town Surrounds';
      }
      if (codeValue.includes('cdb_')) {
        guidebook = 'Cederberg';
      }
      if (codeValue.includes('krc_')) {
        guidebook = 'Karoo Crags';
      }
      if (codeValue.includes('mtg_') || codeValue.includes('MR01') || codeValue.includes('MR03') || codeValue.includes('MR12')) {
        guidebook = 'Montagu';
      }
      if (codeValue.includes('tgr_')) {
        guidebook = 'The Garden Route';
      }

      if (user.montaguAccess) {

        firebase.extendGuide({
          userId: user.uid,
          typedCode: codeValue,
          guide: guidebook,
          initialUntil: user.montaguUntil,
          initialCode: user.montaguCode
        }).then(() => {

          firebase.extendCode({
            codeId: user.montaguCode,
            extendedCode: codeValue
          }).then(() => {
            // code extended
            setCodeValue('');
            setRedeemLoading(false);
            setRedeemSuccessMessage('You have extended your access to ' + guidebook + '! Please hang tight while we update your profile.');
            setTimeout(() => {
              setRedeemLoading(true);
            }, 5000);
            setTimeout(() => {
              handleReload();
            }, 7000);
          }).catch(error => {
            setRedeemErrorMessage(error.message);
          });

        }).catch(error => {
          setCodeValue('');
          setRedeemLoading(false);
          setRedeemErrorMessage(error.message);
        });
        
      } else {

        firebase.redeemGuide({
          userId: user.uid,
          typedCode: codeValue,
          guide: guidebook
        }).then(() => {
          setCodeValue('');
          setRedeemLoading(false);
          setRedeemSuccessMessage('Congratulations, you now have full access to ' + guidebook + '! Please hang tight while we update your profile.');
          setTimeout(() => {
            setRedeemLoading(true);
          }, 5000);
          setTimeout(() => {
            handleReload();
          }, 7000);
        }).catch(error => {
          setCodeValue('');
          setRedeemLoading(false);
          setRedeemErrorMessage(error.message);
        });

      }
      
    } else {

      setRedeemLoading(false);
      setRedeemErrorMessage('There\'s an issue with the code you provided.');
    }
  }

  // handle code change
  const handleCodeChange = (e) => {
    e.persist();
    setRedeemErrorMessage('');
    setRedeemSuccessMessage('');
    setCodeValue(document.querySelector('input[name="code"]').value);
  }

  // handle reload
  const handleReload = () => {
    window.location.reload();
  }

  // const handleAddCodes = () => {
  //   firebase.addRedemptionCode({
  //     code: 'MR12-HOFhV9l5WO',
  //     duration: 265,
  //     used: false,
  //     batch: 'MR12_100_25-04-2023'
  //   });
  //   console.log('hello');
  // }

  // manually expire guide (Montagu)
  // firebase.devExpireGuide();

  return (
    <>
      <Seo title="Welcome" />
      {!!user && !!user.username && (
        <Header classes="header--hasLogo">
          <Logo link={true} classes="" />
        </Header>
      )}

      <main className={(!user || !user.username ? "center-flex" : "static") + " main--hasLogo"}>
        <div className="container">

          {!!loading || !!loginLoading || !!redeemLoading ? (
            <Loader />
          ) : (
            <>
              {!user ? (
                <>
                  <Logo link={false} classes="logo--large" />
                  <Online>
                      <div className="information text-center">
                        <p>Sign in to your account and start exploring.</p>
                      </div>
                      <form>
                        {!!errorMessage &&
                          <>
                            <div className="message message--error registerError">
                              <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                              <span>{errorMessage}</span>
                            </div>
                          </>
                        }
                        <button className="btn btn--black btn--icon" onClick={handleGoogleClick}><FontAwesomeIcon icon={ faGoogle } size={ 'lg' } />Continue with Google</button>
                        <button className="btn btn--black btn--icon" onClick={handleFacebookClick}><FontAwesomeIcon icon={ faFacebook } size={ 'lg' } />Continue with Facebook</button>
                        <div className="description description--center"><small>Experiencing problems?<br />Email us at <a className="link link--primary" target="_blank" rel="noopener noreferrer" href="mailto:rockappbeta@gmail.com">rockappbeta@gmail.com</a></small><br /><br /></div>
                      </form>
                    </Online>
                    <Offline>
                      <div className="information">
                        <div className="message message--error">
                          <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                          <span>You are currently offline and unfortunately logged out.</span>
                        </div>
                      </div>
                    </Offline>
                </>
              ) : (
                <>
                  {!!user.username ? (
                    <>
                      <Offline>
                        <div className="information">
                          <div className="message message--warning">
                            <div className="icon"><FontAwesomeIcon icon={ faExclamation } /></div>
                            <span>You are currently offline and some features have been disabled.</span>
                          </div>
                        </div>
                      </Offline>
                      {props.data.allGuide.edges.map(edge => (
                        <Book key={edge.node.id} title={edge.node.guideTitle} name={edge.node.name} image={getImage(edge.node.guideImage)}
                          montaguAccess={hasMontaguAccess}
                        />
                      ))}
                      <div className="information">
                        <p className="text-center"><strong>More guides coming soon!</strong></p>
                        {/* <button onClick={handleAddCodes}>Add Code</button> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <Logo link={false} classes="logo--large" />
                      {!!successMessage &&
                        <div className="message message--success registerSuccess">
                          <div className="icon"><FontAwesomeIcon icon={ faCheck } /></div>
                          <span>{successMessage}</span>
                        </div>
                      }
                      <Online>
                        <div className="information text-center">
                          <p>Please provide a username*</p>
                        </div>
                        <form>
                          <div className="input-group">
                            <input onChange={handleUsernameChange} value={usernameValue} name="username" type="text" placeholder="Username" required />
                          </div>
                          {!!errorMessage &&
                            <div className="message message--error usernameError">
                              <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                              <span>{errorMessage}</span>
                            </div>
                          }
                          <button className="btn btn--black" onClick={handleUsernameClick}>Set username</button>
                        </form>
                      </Online>
                      <Offline>
                        <div className="information">
                          <div className="message message--error usernameError">
                            <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                            <span>It seems you've never set a username. Please rectify this once you are connected again.</span>
                          </div>
                        </div>
                      </Offline>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {!!loading || !!loginLoading || !!redeemLoading ? (
            <></>
          ) : (
            <>
              {!user || !user.username ? (
                <Mountains />
              ) : null}
            </>
          )}
        </div>
      </main>

      {!!user && !!user.username && (
        <>
          <Online>
            <div className="redemption">
              <div className="text">
                <strong><a href="https://bluemountainpublishers.co.za/rock-app/" rel="nofollow noreferrer">Purchase a subscription</a></strong>
              </div>
              {!!redeemErrorMessage &&
                <div className="message message--error">
                  <div className="icon"><FontAwesomeIcon icon={ faTimes } /></div>
                  <span>{redeemErrorMessage}</span>
                </div>
              }
              {!!redeemSuccessMessage &&
                <div className="message message--success">
                  <div className="icon"><FontAwesomeIcon icon={ faCheck } /></div>
                  <span>{redeemSuccessMessage}</span>
                </div>
              }
              <form onSubmit={handleRedeem}>
                <div className="input-group">
                  <input onChange={handleCodeChange} value={codeValue} name="code" type="text" placeholder="Enter activation code" required />
                </div>
                <button className="btn btn--primary" type="submit">Go</button>
              </form>
            </div>
          </Online>
          <Footer />
        </>
      )}
    </>
  );
}

export const query = graphql`
  {
    allGuide {
      edges {
        node {
          id
          name
          guideTitle
          guideImage {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

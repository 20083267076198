import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import "./Book.scss";

const Book = ({name, title, image, montaguAccess}) => {

  let BlueMountain = loadable(() => import('../../assets/blue-mountain.inline.svg'));

  const [hasAccess, setAccess] = useState(false);
  
  useEffect(() => {
    if (name === 'Montagu') {
      setAccess(montaguAccess);
    } else {
      setAccess(false);
    }
  }, [name, montaguAccess]);

  return (
    <div className="book">
      <Link to={sluggify(name)}>
        {hasAccess ? (
          <div className="book__access book__access--full">
            <FontAwesomeIcon icon={ faCheck } size={ 'sm' } />
          </div>
        ) : (
          <div className="book__access book__access--limited">
            <FontAwesomeIcon icon={ faExclamationTriangle } size={ 'sm' } />
          </div>
        )}
        <div className="book__details">
          <div className="book__details__logo">
            <BlueMountain />
          </div>
          <span className="book__details__author">Tony Lourens</span>
          <span className="book__details__title">{title}</span>
        </div>
        <div className="book__click">
          <FontAwesomeIcon icon={ faArrowRight } size={ 'sm' } />
        </div>
        <GatsbyImage image={image} alt={name} className="book__cover" />
      </Link>
    </div>
  )
}

export default Book;